import React from "react";
import classes from "./Home.module.css";
import HeroSection from "../../components/Home/HeroSection/HeroSection";
import Features from "../../components/Home/Features/Features";
import clsx from "clsx";
import GetStarted from "../../components/Home/GetStarted/GetStarted";
import FocusOnBusiness from "../../components/Home/FocusOnBusiness/FocusOnBusiness";
import CreateSeverAndHostProject from "../../components/Home/CreateSeverAndHostProject/CreateSeverAndHostProject";
import PowerdByCommunity from "../../components/Home/PowerdByCommunity/PowerdByCommunity";
import OtherPages from "../../components/Home/OtherPages/OtherPages";
import HowItWorks from "../../components/Home/HowItWorks/HowItWorks";
import DeployYourFirstProject from "../../components/Home/DeployYourFirstProject/DeployYourFirstProject";
import Faqs from "../../components/Home/Faqs/Faqs";

const Home = () => {
  return (
    <div className={clsx(classes.wrapper, "container")}>
      <HeroSection />
      <Features />
      {/* <GetStarted /> */}
      <FocusOnBusiness />
      <CreateSeverAndHostProject />
      <PowerdByCommunity />
      {/* <OtherPages /> */}
      <HowItWorks />
      <Faqs />
      {/* <DeployYourFirstProject /> */}
    </div>
  );
};

export default Home;
